<script setup lang="ts">
/**
 * Dev- & staging-only command palette for QA
 */

interface Action {
  id: string
  label: string
  icon: string
  click?: () => void
}

const auth = useAuth()
const projectId = auth.project!.id

async function resetRfq() {
  const res = await useCoreData('/market-maker-rfq/admin/status-update/{projectId}', {
    method: 'POST',
    path: { projectId },
  })
}

const actions: Action[] = [
  {
    id: 'reset-rfq',
    label: 'Reset RFQ',
    icon: 'i-heroicons-arrow-path',
    click: () => resetRfq(),
  },
]

const groups = [
  {
    key: 'actions',
    commands: actions,
  },
]

function onSelect(option: Action) {
  if (option.click) {
    option.click()
    isOpen.value = false
  }
}

const isOpen = ref(false)

defineShortcuts({
  shift_meta_k: {
    usingInput: true,
    handler: () => {
      isOpen.value = !isOpen.value
    },
  },
})
</script>

<template>
  <UModal v-model="isOpen">
    <UCommandPalette
      :autoselect="false"
      :groups="groups"
      :searchable="false"
      @update:model-value="onSelect"
    />
  </UModal>
</template>
